<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header
                    :title="$t('student_affairs_grade_import')"
                    :isFilter="false" />
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile
                    :title="$t('student_affairs_grade_import')"
                    :isFilter="false" />
            </template>
            <div class="data-form">
                <ValidationObserver ref="importForm">
                    <div class="row">
                        <div class="col-12">
                            <ValidationProvider name="file" rules="required" v-slot="{errors}">
                                <b-form-group :label="$t('excel_file')">
                                    <div class="d-flex custom-file-upload">
                                        <b-form-file
                                            v-model="defineForm.file"
                                            :state="errors[0] ? false : null"
                                            :placeholder="$t('select_file')"
                                            :drop-placeholder="$t('drop_file')"
                                            ref="fileInput"></b-form-file>
                                        <b-button variant="outline-secondary" @click="$refs.fileInput.$el.childNodes[0].click();">{{ $t('browse') }}</b-button>
                                    </div>
                                    <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                    <b-table class="mt-4" borderless small bordered thead-tr-class="table-success" :items="tableItems" :fields="tableFields"></b-table>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 d-flex justify-content-center">
                            <processing-button :processing="processing"
                                               :label="$t('import')"
                                               variant="primary"
                                               @click="sendForm"/>
                        </div>
                    </div>
                </ValidationObserver>
            </div>
        </app-layout>

    </div>
</template>

<script>
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"

import {ValidationObserver, ValidationProvider} from "vee-validate"
import ProcessingButton from "@/components/elements/ProcessingButton"
import StudentCourseService from "@/services/StudentCourseService"
import qs from "qs"

export default {
    name: "StudentAffairsEntryImport",
    components: {
        AppLayout,
        Header,
        HeaderMobile,

        ProcessingButton,

        ValidationProvider,
        ValidationObserver,
    },
    metaInfo() {
        return {
            title: this.$t('student_affairs_grade_import')
        }
    },
    data() {
        return {
            defineForm: {
                file: null
            },
            processing: false,

            tableFields: [
                {
                    key: 'academic_year',
                    label: this.$t('sample_template_excel'),
                    tdClass: 'border',
                },
                {
                    key: 'period',
                    label: '',
                    tdClass: 'border',
                },
                {
                    key: 'number',
                    label: '',
                    tdClass: 'border',
                },
                {
                    key: 'course_code',
                    label: '',
                    tdClass: 'border',
                },
                {
                    key: 'section',
                    label: '',
                    tdClass: 'border',
                },
                {
                    key: 'letter_grade',
                    label: '',
                    tdClass: 'border',
                },
                {
                    key: 'exam_type',
                    label: '',
                    tdClass: 'border',
                }
            ],
            tableItems: [
                {
                    academic_year: this.$t('student_affairs_academic_year'),
                    period: this.$t('student_affairs_period'),
                    number: this.$t('student_number'),
                    course_code: this.$t('course_code'),
                    section: this.$t('section'),
                    letter_grade: this.$t('letter_grade'),
                    exam_type: this.$t('student_affairs_exam_type')
                },
                {
                    academic_year: '2023-2024',
                    period: '1',
                    number: '111111',
                    course_code: 'DERS111',
                    section: '1',
                    letter_grade: 'A',
                    exam_type: 'F'
                }
            ]
        }
    },
    methods: {
        async sendForm(){
            const isValid = await this.$refs.importForm.validate();
            if (isValid) {
                this.processing = true
                let formData = new FormData()
                formData.append('file', this.defineForm.file)

                StudentCourseService.importLetterGradeForStudentAffairs(formData).then(response => {
                    this._downloadFile(response,this.$t('results')+'.xlsx')
                    this.$toast.success(this.$t('proccess_success'))
                    this.processing = false
                }).catch(e => {
                    this.showErrors(e, this.$refs.importForm)
                    this.processing = false
                })
            }
        }
    }
};
</script>

